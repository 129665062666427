// import imageIconUrl from '../images/image_black_24dp.svg'
// import pdfIconUrl from '../images/picture_as_pdf_black_24dp.svg'
// import xlsIconUrl from '../images/table_chart_black_24dp.svg'

let maxFileSize = 1024 * 1024 * 2; // 2 MB

document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  var list = new DataTransfer();

  function updateFileListUI() {
    let files = list.files;
    // console.log("FILES:");
    // console.log(files, Array.from(files));

    let removeButtonTemplate = document.getElementById("remove-button-template").innerHTML;
    let updatedHTML = "";

    Array.from(files).forEach(file => {
      let iconUrl;
      let ft = file.type;
      switch(file.type) {
        case 'application/pdf':
          iconUrl = "mdi:file-pdf-box";
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
        case 'application/vnd.ms-excel':
        case 'application/vnd.ms-excel.sheet.macroEnabled.12':
          iconUrl = "mdi:google-spreadsheet";
          break;
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
        case 'application/vnd.ms-word.document.macroEnabled.12':
        case 'application/vnd.ms-word.template.macroEnabled.12':
          iconUrl = "mdi:file-word"
          break;
        case 'image/png':
          iconUrl = "mdi:file-png-box";
          break;
        default:
          iconUrl = "mdi:file-jpg-box";
          break;
      }
      let html = `
        <li class="list-qoutes-item">
          <span class="list-qoutes-item__graphic">
            <i class="iconify-inline" data-icon=${iconUrl}></i>
          </span>
          <span class="list-qoutes-item__text">${file.name}</span>
          <button class="mdc-icon-button mdc-icon-button--small list-qoutes-item__remove-button remove-uploaded-file" data-file-name="${file.name}">
            <div class="mdc-icon-button__ripple"></div>
            <span class="mdc-icon-button__focus-ring"></span>
            ${removeButtonTemplate}
          </button>
        </li>
      `;
      updatedHTML = updatedHTML + html;
    });
    let parent = document.getElementById("uploaded-file-list");
    let children = parent.querySelectorAll('.list-qoutes-item');
    children.forEach(function(child) {
      if (!child.classList.contains('persisted')) {
        parent.removeChild(child);
      }
    })
    parent.innerHTML = parent.innerHTML + updatedHTML;
    //
    $(".remove-uploaded-file").off();
    $(".remove-uploaded-file").click(removeFile);
  }

  function removeFile(e) {
    e.preventDefault();
    e.stopPropagation();

    let fn = $(this).data('file-name');
    for (var i = list.items.length - 1; i >= 0; i--) {
      let item = list.items[i];
      let file = item.getAsFile();
      if (file && file.name == fn) {
        list.items.remove(i);
      }
    }
    document.getElementById("bid_files").files = list.files;
    updateFileListUI();
  }

  function notifyOversizedFiles(names) {
    if (names.length > 0) {
      let joined = names.join(", ");
      alert('Maximum file size is 2MB:\n' + joined);
    }
  }
  /**
   * Dropzone
   */
  $("#file-dropzone").off();
  $("#file-dropzone").on("drop", function(e) {
    e.preventDefault();
    e.stopPropagation();

    let oversizedFileNames = [];

    // Get elements
    let dt = e.originalEvent.dataTransfer;
    if (dt.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < dt.items.length; i++) {
        // If dropped items aren't files, reject them
        if (dt.items[i].kind === 'file') {
          let file = dt.items[i].getAsFile();
          if (file.size > maxFileSize) {
            oversizedFileNames.push(file.name);
          } else {
            list.items.add(file);
          }
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < dt.files.length; i++) {
        let file = dt.files[i];
        if (file.size > maxFileSize) {
          oversizedFileNames.push(file.name);
        } else {
          list.items.add(file);
        }
      }
    }
    document.getElementById("bid_files").files = list.files;
    updateFileListUI();
    notifyOversizedFiles(oversizedFileNames);
  });
  $("#file-dropzone").on('dragover', function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).addClass('dropzone-highlight');
  })
  $("#file-dropzone").on("dragleave", function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).removeClass('dropzone-highlight');
  });
  /**
   * Input file onchange
   */
  $("#bid_files").on('change', function(e) {
    let oversizedFileNames = [];

    let files = $(this)[0].files;
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      if (file.size > maxFileSize) {
        oversizedFileNames.push(file.name);
      } else {
        list.items.add(file);
      }
    }
    document.getElementById("bid_files").files = list.files;
    updateFileListUI();
    notifyOversizedFiles(oversizedFileNames);
  });
};
