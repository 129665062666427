document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  // Show Hide Password Input Value
  $("body").on('click', '[data-icon="mdi:eye-off-outline"]', function() {
    this.setAttribute('data-icon', 'mdi:eye-outline');

    const inputPassword = $(this).siblings('input[type="password"]');
    
    if (inputPassword.attr("type") === "password") {
      inputPassword.attr("type", "text");
    } else {
      inputPassword.attr("type", "password");
    }
  });

  $("body").on('click', '[data-icon="mdi:eye-outline"]', function() {
    this.setAttribute('data-icon', 'mdi:eye-off-outline');

    const inputPassword = $(this).siblings('input[type="text"]');
    
    if (inputPassword.attr("type") === "text") {
      inputPassword.attr("type", "password");
    } else {
      inputPassword.attr("type", "text");
    }
  });
};