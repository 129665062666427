document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  $("input[data-type='date']").datepicker("destroy");
  $("input[data-type='date']").datepicker({
    showButtonPanel: true,
    closeText: 'OK',
    onSelect: function(date, inst) {
      // changeMaterialHeader(headerHtml, moment(date, 'MM/DD/YYYY'));
    },
  });
}
