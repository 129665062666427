document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);
  
  // Avatar Placeholder
  let businessName = document.getElementById('current-business-name');

  if (typeof(businessName) !== 'undefined' && businessName!== null) {
    const businessName = document.getElementById('current-business-name').textContent;
    const initials = businessName.charAt(0).toUpperCase();
    const avatarPlaceholders = document.querySelectorAll('.avatar__initial-placeholder');
    for (const avatarPlaceholder of avatarPlaceholders) {
      avatarPlaceholder.innerHTML = initials;
    }
  }
};  