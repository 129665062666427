import intlTelInput from 'intl-tel-input';

document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);
  const telInput = document.querySelector(".telephone-field__input");
  if (telInput) {
    var iti = intlTelInput(telInput, {
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/utils.min.js",
        onlyCountries: ['US'],
        preferredCountries: ['US'],
        nationalMode: false,
        separateDialCode: true,
        formatOnDisplay: true,
        allowDropdown: false
      }
    );
    telInput.addEventListener('keyup', formatIntlTelInput);
    telInput.addEventListener('change', formatIntlTelInput);
  }
  function formatIntlTelInput() {
    // console.log("formatting - ", typeof intlTelInputUtils);
    if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
      let currentText = iti.getNumber(intlTelInputUtils.numberFormat.E164);
      let actualInput = telInput.parentElement.parentElement.querySelector('.hidden-phone-field');
      actualInput.value = currentText;

      if (typeof currentText === 'string') { // sometimes the currentText is an object :)
        iti.setNumber(currentText); // will autoformat because of formatOnDisplay=true
      }
    }
  }
};
