document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  $(".company_logo_input").change(function(e) {
    var input = this;
    var url = $(this).val();
    var ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
    if (input.files && input.files[0]&& (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg")) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $(".mdc-file-field__avatar-img").get(0).src = e.target.result;
        $(".mdc-file-field__avatar-img").show();
      }
      reader.readAsDataURL(input.files[0]);
    }
  });

  $(".help-and-feedback-button").click(function(e) {
    e.preventDefault();
    Intercom('show');
  });
};
