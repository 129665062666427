import Timer from "easytimer.js";
const timer = new Timer();
let emailConfirmation = '.email-confirmation';

// Resend confirmation token
$('.resend-confirmation-token', emailConfirmation).on('click', function () {
  let $that = $(this),
    email = $that.data('email');
  // Set button to disabled
  $that.prop('disabled', true);

  // Start timer
  let timeout = 30;
  timer.start({ countdown: true, startValues: { seconds: timeout } });

  // Show timer
  $('#countdown', emailConfirmation).html(timer.getTimeValues().toString(['minutes', 'seconds']));

  timer.addEventListener('secondsUpdated', function (e) {
    $('#countdown', emailConfirmation).html(timer.getTimeValues().toString(['minutes', 'seconds']));
  });

  timer.addEventListener('targetAchieved', function (e) {
    $('#countdown', emailConfirmation).html('');
    $that.prop('disabled', false);
  });

  // Send AJAX request to resend confirmation token
  resendConfirmationToken(email);
});

function resendConfirmationToken(email) {
  $.ajax({
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    method: 'POST',
    url: '/users/confirmation',
    data: {
      user: { email: email }
    },
    dataType: 'json'
  })
}
