document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  $("#material-requested-tab").off();
  $("#material-requested-tab").click(function(e) {
    e.preventDefault();
    if ($(".material-details-table").hasClass('hidden')) {
      $(".material-details-table").removeClass('hidden');
      $(".documents-list").addClass('hidden');
    }
  })
  $("#document-uploaded-tab").off();
  $("#document-uploaded-tab").click(function(e) {
    e.preventDefault();
    if ($(".documents-list").hasClass('hidden')) {
      $(".documents-list").removeClass('hidden');
      $(".material-details-table").addClass('hidden');
    }
  })

  $("#button_direct_offer").off();
  $("#button_direct_offer").click(function(e) {
    e.preventDefault();
    updateOfferForWithPaymentMethodAndSubmit('direct');
  })

  $("#button_finance_offer").off();
  $("#button_finance_offer").click(function(e) {
    e.preventDefault();
    updateOfferForWithPaymentMethodAndSubmit('finance');
  })

  $(".material-request-row").off();
  $(".material-request-row").click(function(e) {
    $(this).find(".detail-link")[0].click();
  })

  $(".material-option-select").off();
  $(".material-option-select").click(function(e) {
    e.stopPropagation();
  })

  // $(".show-spinner").off();
  $(".show-spinner").click(function(e) {
    $("#overlay").fadeIn(300);
  })
};
/**
 * Offer buying options select
 */
function updateOfferForWithPaymentMethodAndSubmit(payment_method) {
    if (confirm('Are you sure?')) {
        let form = document.getElementById('submit-offer-form');
        let field = form.querySelector('#offer_selected_payment_type');
        field.value = payment_method;
        form.submit();
    } else {
        // Do nothing!
    }
}
