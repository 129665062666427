import {MDCDrawer} from "@material/drawer";

document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  let sideDashboard = document.querySelector('.side-bar--dashboard');
  if (sideDashboard) {
    const drawer = new MDCDrawer.attachTo(sideDashboard);

    document.querySelectorAll('.hamburger-menu-button').forEach(hamburgerMenu => {
      hamburgerMenu.addEventListener('click', event => {
        event.preventDefault();
        drawer.open = true;
      });
    });

    const listEl = document.querySelector('.mdc-drawer .mdc-list');
    const mainContentEl = document.querySelector('.webapp');

    listEl.addEventListener('click', (event) => {
      drawer.open = false;
    });

    document.body.addEventListener('MDCDrawer:closed', () => {
      mainContentEl.querySelector('input, button').focus();
    });
  }
};
