document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  $(".sortable-table-header").off();
  $(".sortable-table-header").click(function(event) {
    event.preventDefault();
    let sort_by = $(this).data('sort-by');
    let direction = $(this).data('sort-direction');
    direction = direction == 'asc' ? 'desc' : 'asc'

    let val = sort_by + " " + direction;
    $("#sort_by").val(val);
    $("form#sortable_form").submit();
  });
};
