import {MDCRipple} from '@material/ripple';
import {MDCTextField} from '@material/textfield';
import {MDCSelect} from '@material/select';
import {MDCList} from '@material/list';
import {MDCSnackbar} from '@material/snackbar';
import {MDCDataTable} from '@material/data-table';
import {MDCChipSet} from '@material/chips';
import {MDCTabBar} from '@material/tab-bar';
import {MDCDialog} from '@material/dialog';
import {MDCSwitch} from '@material/switch';
import {MDCBanner} from '@material/banner';
import {MDCTooltip} from '@material/tooltip';
import {MDCTextFieldIcon} from '@material/textfield/icon';

document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
    document.removeEventListener('DOMContentLoaded', initFns);

    // const textField = new MDCTextField(document.querySelectorAll('.mdc-text-field'));
    const textFields = [].map.call(document.querySelectorAll('.mdc-text-field'), function(el) {
        return new MDCTextField(el);
    });

    const selectFields = [].map.call(document.querySelectorAll('.mdc-select'), function(el) {
        let select = new MDCSelect(el);
        select.listen('MDCSelect:change', () => {
            // console.log(select.attr('id'));
            // console.log(el);
            // console.log(el.dataset.selectName);
            let selectName = el.dataset.selectName;
            console.log(`${selectName} - Selected option at index ${select.selectedIndex} with value "${select.value}"`);

            if (selectName == "pagination") {
                let form = document.getElementById("rfq-pagination-form");
                let per_page_field = form.querySelector('#per-page-field');
                per_page_field.value = parseInt(select.value);
                form.submit();
            } else {
                let textEle = el.querySelector(".mdc-select__selected-text");
                if (select.value == "") {
                    textEle.classList.add("placeholder-color");
                } else {
                    textEle.classList.remove("placeholder-color");
                }
            }
        });
        return select;
    });

    const lists = [].map.call(document.querySelectorAll('.mdc-list'), function(el) {
        return new MDCList(el);
    });

    const snackbar = new MDCSnackbar(document.querySelector('.mdc-snackbar'));
    let noticeText = document.getElementById("flash_notice").innerText;
    if (noticeText && noticeText.length > 0) {
        let el = document.querySelector('.mdc-snackbar');
        el.classList.remove("mdc-snackbar--alert");
        snackbar.labelText = noticeText;
        snackbar.open();
    } else {
        // Might want to style it accordingly
        let alertText = document.getElementById("flash_alert").innerText;
        if (alertText && alertText.length > 0) {
            let el = document.querySelector('.mdc-snackbar');
            el.classList.add("mdc-snackbar--alert");
            snackbar.labelText = alertText;
            snackbar.open();
        }
    }

    let mdcTable = document.querySelector('.mdc-data-table')
    if (mdcTable) {
        const dataTable = new MDCDataTable(mdcTable);
    }

    const chipsets = [].map.call(document.querySelectorAll('.mdc-evolution-chip-set'), function(el) {
        return new MDCChipSet(el);
    });

    const tabBars = [].map.call(document.querySelectorAll('.mdc-tab-bar'), function(el) {
        let tabBar = new MDCTabBar(el);
        tabBar.listen('MDCTabBar:activated', function(event) {
            console.log(event);
            if (el.id === "rfq-detail-tab-bar") {
                let tabCount = document.querySelectorAll(".mdc-tab").length;
                let index = event.detail.index;

                if (tabCount == 4) {
                    $('#show-partial-offer').hide();
                    $('#show-bid-submitted').hide();
                    $('#show-material-request').hide();
                    $('#show-files').hide();

                    if (index == 0) {
                        $('#show-partial-offer').show();
                    } else if (index == 1) {
                        $('#show-bid-submitted').show();
                    } else if (index == 2) {
                        $('#show-material-request').show();
                    } else { // 3
                        $('#show-files').show();
                    }
                } else if (tabCount == 3) {
                    if (index == 0) {
                        $('#show-bid-submitted').show();
                        $('#show-material-request').hide();
                        $('#show-files').hide();
                    } else if (index == 1) {
                        $('#show-bid-submitted').hide();
                        $('#show-material-request').show();
                        $('#show-files').hide();
                    } else {
                        $('#show-bid-submitted').hide();
                        $('#show-material-request').hide();
                        $('#show-files').show();
                    }
                } else {
                    if (index == 0) {
                        $('#show-material-request').show();
                        $('#show-files').hide();
                    } else {
                        $('#show-material-request').hide();
                        $('#show-files').show();
                    }
                }
            }
        });
        return tabBar;
    });

    /**
     * MDC Dialog
     */
    let dialogEle = document.querySelector('#dialog-bid-success');
    if (dialogEle) {
        let dialog = new MDCDialog(dialogEle);
        dialog.open();
    }
    $(".invite-client-button").click(function(e) {
        e.preventDefault();
        let inviteDialog = document.querySelector('#dialog-invite-clients');
        if (inviteDialog) {
            let dialog = new MDCDialog(inviteDialog);
            dialog.open();
        }
    })
    
    const switchControl = [].map.call(document.querySelectorAll('.mdc-switch'), function(el) {
        return new MDCSwitch(el);
    });
    
    const banner = [].map.call(document.querySelectorAll('.mdc-banner'), function(el) {
        return new MDCBanner(el);
    });

    const buttonRipple = [].map.call(document.querySelectorAll('.mdc-button'), function(el) {
        return new MDCRipple(el);
    });

    const tooltip = [].map.call(document.querySelectorAll('.mdc-tooltip'), function(el) {
        return new MDCTooltip(el);
    });

    const icon = [].map.call(document.querySelectorAll('.mdc-text-field-icon'), function(el) {
        return new MDCTextFieldIcon(el);
    });
};

/**
 * Offer buying options select
 */
function handleDirectOffer(id) {
    updateOfferForWithPaymentMethodAndSubmit(id, 'direct');
}
function handleFinanceOffer(id) {
    updateOfferForWithPaymentMethodAndSubmit(id, 'finance');
}
function updateOfferForWithPaymentMethodAndSubmit(id, payment_method) {
    if (confirm('Are you sure?')) {
        let arr = id.split("-");
        arr.shift();
        let identifier = arr.join("-");

        let form = document.getElementById(`offer-${identifier}`);
        let field = form.querySelector('#offer_selected_payment_type');
        field.value = payment_method;
        form.submit();
    } else {
        // Do nothing!
    }
}
