import {MDCMenu} from '@material/menu';

document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  const menuButton = document.getElementById('account-menu-button');
  if (!menuButton) {
    return;
  }
  const menu = new MDCMenu(menuButton.nextElementSibling);
  const menuScrim = document.querySelector('.mdc-menu-scrim');

  menuButton.addEventListener('click', event => {
    event.preventDefault();
    menu.open = !menu.open;
    menu.setAbsolutePosition(-56, 36);    
    menuScrim.style.display = "block";
  })

  menuScrim.addEventListener("click", (event) => {
    menuScrim.style.display = "none";
    menu.open = false;
  });

};
