import {MDCMenu} from '@material/menu';
import {Corner} from '@material/menu';

document.addEventListener('DOMContentLoaded', initFns);
document.addEventListener('turbo:render', initFns);
function initFns(event) {
  document.removeEventListener('DOMContentLoaded', initFns);

  function calculateTotalPrice() {
    var totalPrice = 0;
    $(".input-list__item").each(function() {
      var price = 0;
      var quantity = 0;

      let not_available = $(this).find('.not_available_field').first().val();
      if (not_available === 'true') {
        return;
      }

      let swapped = $(this).find('.swapped_field').first().val();
      if (swapped === 'true') {
        price = $(this).find('.material-cost-replacement').first().val();
        quantity = $(this).find('.material-quantity-replacement').first().val();
      } else {
        price = $(this).find('.material-cost-original').first().val();
        quantity = $(this).find('.material-quantity').first().val();
      }
      price = ep_currency_to_number(price);
      quantity = ep_currency_to_number(quantity);

      totalPrice = totalPrice + (price * quantity);
    });
    $("#material-total-cost").html(('$' + ep_number_to_currency(totalPrice)));
    $("#bid_total_price").val(totalPrice);
  }
  calculateTotalPrice();

  $('.material-cost').on('input', function(e) {
    calculateTotalPrice();
  });
  $('.quantity-field').on('input', function(e) {
    calculateTotalPrice();
  });

  // Dropdown

  var handleMenuSelection = false;
  document.querySelectorAll('.more-menu-trigger').forEach(bidMenu => {
    const menu = new MDCMenu(bidMenu.nextElementSibling);
    menu.setAnchorCorner(Corner.BOTTOM_END);
    menu.setAnchorMargin({right: 135});
    menu.listen('MDCMenu:selected', (event) => {
      if (!handleMenuSelection) {
        return;
      }
      handleMenuSelection = false;

      let parent = bidMenu.closest(".input-list__item");
      parent.classList.remove("input-list__item-swapped", "input-list__item-unavailable");

      let el = event.detail.item;
      let textEle = el.querySelector(".mdc-list-item__text");

      let inputLabel = parent.querySelectorAll(".input-list__request-fields .mdc-text-field");
      inputLabel.forEach(i => {
        i.classList.remove("mdc-text-field--disabled");
      })
      let inputField = parent.querySelectorAll(".input-list__request-fields .mdc-text-field__input");
      inputField.forEach(i => {
        i.readOnly = false;
        i.disabled = false;
      })

      let updatedText = "";
      let updatedAction = "";
      let icon = "";

      let isSwapped = false;
      let isNotAvailable = false;

      let cmd = el.dataset.action;
      if (cmd == "swap") {
        updatedText = "Unswap";
        updatedAction = "unswap";
        icon = "mdi:swap-vertical-circle";
        parent.classList.add("input-list__item-swapped");
        //
        isSwapped = true;
      }
      else if (cmd == "unswap") {
        updatedText = "Swap";
        updatedAction = "swap";
      }
      else if (cmd == "not_available") {
        updatedText = "Available";
        updatedAction = "available";
        icon = "mdi:minus-circle";
        //
        parent.classList.add("input-list__item-unavailable");
        let inputLabel = parent.querySelectorAll(".input-list__request-fields .mdc-text-field");
        inputLabel.forEach(i => {
          i.classList.add("mdc-text-field--disabled");
        })
        let inputField = parent.querySelectorAll(".input-list__request-fields .mdc-text-field__input");
        inputField.forEach(i => {
          i.readOnly = true;
        })
        //
        isNotAvailable = true;
      }
      else if (cmd == "available") {
        updatedText = "Not Available";
        updatedAction = "not_available";
      }
      el.dataset.action = updatedAction;
      textEle.innerText = updatedText;

      let iconEl = parent.querySelector(".iconify-inline");
      iconEl.dataset.icon = icon;

      parent.querySelector(".swapped_field").value = isSwapped;
      parent.querySelector(".not_available_field").value = isNotAvailable;

      calculateTotalPrice();
    });

    bidMenu.addEventListener('click', event => {
      event.preventDefault();
      handleMenuSelection = true;
      menu.open = true;
    })
  })
}
